<template>
  <div v-if="showCustomer">
    <div style="padding:15px;background:#fff;" v-if="Object.keys(salesperson).length > 0">
      <p>
        <small>
          <span v-if="storeId!='482803'">客户经理：</span>{{ salesperson.name }}
        </small>
      </p>
    </div>
  </div>
  <van-form @submit="onSubmit">
    <van-cell-group title="目前仅支持微信" inset>
      <van-field
        v-model="amount"
        name="amount"
        label="支付金额"
        placeholder="请输入"
      />
    </van-cell-group>
    <van-radio-group v-model="checked">
      <van-cell-group title="支付方式" inset>
        <van-cell title="微信支付" clickable @click="checked = 'WECHAT'">
          <template #right-icon>
            <van-radio name="WECHAT" />
          </template>
        </van-cell>
        <van-cell title="余额支付" clickable @click="checked = 'BALANCE'" v-if="memberId">
          <template #right-icon>
            <van-radio name="BALANCE" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        确认支付
      </van-button>
    </div>
    <div style="margin: 16px;">
      <van-button round block :to="{ name: 'FreePayLog' }">
        支付记录
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import { callWeixinPay } from '@/util/util'

export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      salescollectionpersonId: parseInt(useRoute.query.salescollectionpersonId) || '',
      type: useRoute.query.type || '',
      storeId: Cookies.get('storeId'),
      buyerId: Cookies.get('buyerId'),
      userId: Cookies.get('userId'),
      memberId: Cookies.get('memberId'),
      salespersonId: Cookies.get('salespersonId'),
      wechatOpenid: Cookies.get('wechatOpenid'),
      salesperson: {},
      showCustomer: false,
      amount: '',
      tradeNumber: '',
      checked: 'WECHAT'
    })
    const applyPay = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        let payHttpUrl = '/order.applyPay'
        if (res.data.storeMerchant) {
          if (res.data.storeMerchant.applymentState === 'FINISH') {
            payHttpUrl = '/applyMerchantPay'
          }
        }
        post(payHttpUrl, {
          storeId: state.storeId,
          wechatOpenid: state.wechatOpenid,
          tradeNumber: state.tradeNumber,
          paymentMethod: state.checked
        }).then(res => {
          if (res.code === 0) {
            if (state.checked === 'WECHAT') {
              let payParams = {}
              if (payHttpUrl === '/order.applyPay') {
                payParams = res.data.wechatPayParams
              } else {
                payParams = res.data
                payParams.package = res.data.packageValue
              }
              console.log(payParams)
              callWeixinPay(payParams).then(res => {
                if (res.result) {
                  Toast.success('支付成功')
                  useRouter.push({
                    name: 'User'
                  })
                } else {
                  Toast.fail('支付取消')
                }
              })
            } else if (state.checked === 'BALANCE') {
              Toast.success('支付成功')
              useRouter.push({
                name: 'User'
              })
            }
          } else {
            Toast(res.msg)
          }
        })
      })
    }
    const oldApplyPay = () => {
      post('/order.applyPayall', {
        storeId: state.storeId,
        wechatOpenid: state.wechatOpenid,
        tradeNumber: state.tradeNumber,
        paymentMethod: state.checked
      }).then(res => {
        if (res.code === 0) {
          if (state.checked === 'WECHAT') {
            res.data.wechatPayParams.packageValue = res.data.wechatPayParams.package
            callWeixinPay(res.data.wechatPayParams).then(res => {
              if (res.result) {
                Toast.success('支付成功')
                useRouter.push({
                  name: 'User'
                })
              } else {
                Toast.fail('支付取消')
              }
            })
          } else if (state.checked === 'BALANCE') {
            Toast.success('支付成功')
            useRouter.push({
              name: 'User'
            })
          }
        } else {
          Toast(res.msg)
        }
      })
    }
    const onSubmit = (values) => {
      Toast.loading({ duration: 0, forbidClick: true })
      const data = {
        buyerId: state.buyerId,
        storeId: state.storeId,
        salespersonId: state.salespersonId,
        amount: values.amount
      }
      if (state.salescollectionpersonId) data.salescollectionpersonId = state.salescollectionpersonId
      if (state.type === 'store_recharge') {
        post('/order.freePayalliance', data).then(res => {
          if (res.code === 0) {
            state.tradeNumber = res.data.tradeNumber
            oldApplyPay()
          } else {
            Toast.clear()
            Toast(res.msg)
          }
        })
      } else {
        post('/order.freePay', data).then(res => {
          if (res.code === 0) {
            state.tradeNumber = res.data.tradeNumber
            applyPay()
          } else {
            Toast.clear()
            Toast(res.msg)
          }
        })
      }
    }
    const init = () => {
      // post('/buyer.get', {
      //   buyerId: state.buyerId
      // }).then(res => {
      //   if (res.code === 0) {
      //     state.phone = res.data.user.phone
      //   } else {
      //     Toast(res.msg)
      //   }
      // })
      if (state.type === 'store_recharge') {
        document.title = '红包充值'
      }
      if (state.storeId) {
        post('/store.get', {
          storeId: state.storeId
        }).then(res => {
          state.showCustomer = res.data.isswitch
          if (state.showCustomer) {
            if (state.buyerId) {
              post('/customer.get', {
                buyerId: state.buyerId,
                storeId: state.storeId
              }).then(res => {
                if (res.code === 0) {
                  state.salesperson.name = res.data.staffName
                }
              })
            }
          }
        })
      }
      // if (state.salespersonId) {
      //   post('/staff.get', {
      //     storeId: state.storeId,
      //     staffId: state.salespersonId
      //   }).then(res => {
      //     if (res.code === 0) {
      //       state.salesperson = res.data
      //     }
      //   })
      // }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>

<style scoped>
</style>
